<template>
    <div class="home"
         id="homeId">
        <van-overlay class="initLoading"
                     :show="initLoading"
                     :lock-scroll="true">
            <van-loading color="#999"
                         type="spinner" />
        </van-overlay>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <template #pulling="props">
            <div class="refresh">加载中...</div>
        </template>
        <template #loosing>
            <div class="refresh">加载中...</div>
        </template>
        <div class="topBanner">
            <van-swipe class="my-swipe"
                       :autoplay="3000"
                       indicator-color="white">
                <van-swipe-item v-for="item,index in topBanner"
                                :key="index">
                    <img class="bannerImg"
                         @click="goUrl(item, '')"
                         :src="item.pic"
                         alt="" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div v-if="userInfo" class="user">
            <div class="userInfo">
                <div class="name_info">
                    <div class="left_user_box">
                        <img :src="userInfo.avatarUrl">
                        <div class="grade_info">
                            <p>{{userInfo.nickName}}</p>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="money">
                    <div class="text">累计收入:&nbsp;
                        <span class="num">{{totalAmount}}</span>
                        <span class="unit">元</span>
                    </div>
                    <span @click="checkMore" class="more">查看更多 <van-icon size="14" name="arrow" /></span>
                </div>
            </div>
        </div>
        <div v-else class="login">
            <div class="loginIn">
                <div class="tip">
                    <img src="../../sprite/noLogin-icon.jpg"
                         alt="">
                    <span>登录后查看我的收入</span>
                </div>
                <div @click="goLogin"
                     class="btn">登录</div>
            </div>
        </div>
        <div class="waistBanner">
            <div class="centerIn">
                <img @click="goRouter(waistBanner[0].address)"
                     :src="waistBanner[0] && waistBanner[0].pic"
                     alt="" />
                <img @click="goRouter(waistBanner[1].address)"
                     :src="waistBanner[1] && waistBanner[1].pic"
                     alt="" />
            </div>
        </div>
        <div class="tipBanner">
            <div class="content">
                <div class="title">
                    分享赚佣金
                    <span class="tips">(注意：自购订单无法获得佣金)</span>
                </div>
                <img src="../../sprite/tipBanner.jpg" alt="">
                <div class="text">
                    <span>分享给好友</span>
                    <span>好友开通会员</span>
                    <span>佣金入账</span>
                </div>
            </div>
        </div>
        <div class="tipSrcoll" v-if="textList.length > 0">
            <div class="content">
                <div class="tipUser"
                    ref="tipUser">
                    <img src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/b36ae2b5ccf345e4a6880848c286fee2.png"
                        alt="">{{textList[count] && textList[count].userNicker}}在
                    <span>{{textList[count] && textList[count].beforeTime}}</span>获得佣金
                    <span>{{textList[count] && textList[count].commissionAmount}}</span>元
                </div>
            </div>

        </div>
        <div class="rankList">
            <div class="centerIn">
                <div class="top">
                    <div class="title">
                        <span>收益排行榜</span>
                    </div>
                    <span class="all"
                          @click="goRankTop">完整排行榜 <van-icon size="13"
                                  name="arrow" /></span>
                </div>
                <div class="topList">
                    <div class="second">
                        <div>
                            <div class="user">
                                <img :src="rankList[1] && rankList[1].avatarUrl"
                                     alt="">
                                <span>{{rankList[1] && rankList[1].userNicker}}</span>
                            </div>
                            <span class="money">{{rankList[1] && rankList[1].saleAmountShort}}<span class="unit" v-if="rankList[1] && rankList[1].saleAmount>10000">万</span><span class="unit">元</span></span>
                            <img class="crown" src="../../sprite/crown-icon2.png" alt="">
                        </div>
                    </div>
                    <div class="first">
                        <div>
                            <div class="user">
                                <img :src="rankList[0] && rankList[0].avatarUrl"
                                     alt="">
                                <span>{{rankList[0] && rankList[0].userNicker}}</span>
                            </div>
                            <span class="money">{{rankList[0] && rankList[0].saleAmountShort}}<span class="unit" v-if="rankList[0] && rankList[0].saleAmount>10000">万</span><span class="unit">元</span></span>
                            <img class="crown" src="../../sprite/crown-icon1.png" alt="">
                        </div>
                    </div>
                    <div class="third">
                        <div>
                            <div class="user">
                                <img :src="rankList[2] && rankList[2].avatarUrl"
                                     alt="">
                                <span>{{rankList[2] && rankList[2].userNicker}}</span>
                            </div>
                            <span class="money">{{rankList[2] && rankList[2].saleAmountShort}}<span class="unit" v-if="rankList[2] && rankList[2].saleAmount>10000">万</span><span class="unit">元</span></span>
                            <img class="crown" src="../../sprite/crown-icon3.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="productList">
            <div class="title">
                <div>
                    <span class="text">当季热销 分享赚钱</span>
                </div>
                <span class="rule" @click="handleViewRule">规则说明<van-icon size="13" name="arrow" /></span>
            </div>
            <!-- <van-list v-model="loading" :finished="finished" @load="onLoad"> -->
            <div class="list">
                <div class="left">
                    <div class="item"
                         @click="shareHandle(item)"
                         v-for="item,index in productList1"
                         :key="index">
                        <img :src="item.productImage" alt="">
                        <div class="title">{{item.productName}}</div>
                        <div class="money">
                            <span><b>¥</b>{{item.salePrice}}<b>{{item.priceUnit?item.priceUnit:''}}</b></span>
                            <span class="del">¥{{item.cost}}{{item.priceUnit?item.priceUnit:''}}</span>
                        </div>
                        <div class="shareBtn">分享赚¥<span>{{item.oneCommission}}</span>{{item.priceUnit?item.priceUnit:''}}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="item"
                         @click="shareHandle(item)"
                         v-for="item,index in productList2"
                         :key="index">
                        <img :src="item.productImage" alt="">
                        <div class="title">{{item.productName}}</div>
                        <div class="money">
                            <span><b>¥</b>{{item.salePrice}}<b>{{item.priceUnit?item.priceUnit:''}}</b></span>
                            <span class="del">¥{{item.cost}}{{item.priceUnit?item.priceUnit:''}}</span>
                        </div>
                        <div class="shareBtn">分享赚¥<span>{{item.oneCommission}}</span>{{item.priceUnit?item.priceUnit:''}}</div>
                    </div>
                </div>
            </div>
            <!-- </van-list> -->
        </div>
        <div class="logo">
            <p>本服务由西藏福禄网络科技有限公司运营</p>
            <img src="../../sprite/logo-bottom.png">
        </div>
        </van-pull-refresh>
        <div class="joinDialog"
             :class="isVisible?'showDialog':'hiddenDialog'">
            <div class="content">
                <div class="joinIn">
                    <div class="title">
                        <img src="../../sprite/joinDialog-bg.png" >
                    </div>
                    <div class="conter">
                        <div class="user">
                            <img :src="userInfo && userInfo.avatarUrl" alt="">
                            <span>{{userInfo && userInfo.nickName}}</span>
                        </div>
                        <img class="tip" src="../../sprite/joinDialog-tip.jpg" >
                        <img class="enterBtn" @click="createUserInfo" src="../../sprite/joinDialog-btn.png">
                        <div class="refuse" @click="isVisible = false">拒绝协议</div>
                        <div class="agreement">请仔细阅读以下协议：
                            <span @click="viewAgreement('/serveAgreement')">《酷联盟服务协议》</span>
                            <span @click="viewAgreement('/commissionAgreement')">《推广人佣金与行为规则》</span>
                        </div>
                    </div>
                </div>
                <div class="close"><span @click="isVisible = false" ></span></div>
            </div>
        </div>
        <van-popup v-model="show"
                   :close-on-click-overlay="false"
                   overlay
                   class="shareModal">
            <div class="shareCentent">
                <van-loading type="spinner"
                             v-if="imgLoading"
                             color="#F0D19E"
                             vertical>生成中...</van-loading>
                <ul v-if="!imgLoading"
                    class="content-swipe">
                    <li v-for="(item, idx) in productSubImageList"
                        :key="item.id">
                        <div class="shareWarp"
                             :ref="`canvasRef_${idx}`"
                             :style="{ display: opacity === 1 ? 'block' : 'none' }">
                            <div class="prodcutImg">
                                <img crossOrigin="anonymous"
                                     :src="item+'?'+new Date().getTime()"
                                     alt="">
                                <!-- <div class="price">¥<span>{{productPrice}}</span></div> -->
                            </div>
                            <div class="content">
                                <div class="userInfo">
                                    <img class="productImg"
                                         :src="userInfo && userInfo.avatarUrl.split('?')[0]+'?'+new Date().getTime()"
                                         alt="">
                                    <div>
                                        <span class="name">{{userInfo && posterNickName}}</span>
                                        <span class="tipText">邀请加入优酷会员</span>
                                    </div>
                                </div>
                                <div class="code">
                                    <div class="qrcode"
                                         :class="`qrcode_${idx}`"
                                         ref="qrCodeUrl"></div>
                                    <span>微信扫码抢购</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="copyAndSaveBtn">
                    <span v-if="!imgLoading"
                          @click="handleCopyAndSave('LINK')">链接分享</span>
                    <span v-if="!imgLoading"
                          @click="handleCopyAndSave('IMG')">图片分享</span>
                </div>
                <div class="close" v-if="!imgLoading"><span @click="closePoster"></span></div>
            </div>
            <van-swipe class="posters-swipe"
                       :autoplay="0"
                       indicator-color="white"
                       @change="onChange">
                <van-swipe-item v-for="(item, idx) in postersList"
                                :key="idx">
                    <div :class="`postersClass_${idx}`"></div>
                </van-swipe-item>
            </van-swipe>
        </van-popup>
        <Rule ref="refRule"></Rule>
        <van-action-sheet v-model="showSheet"
                          title="选择分享方式">
            <div class="sheet-content">
                <div @click="shareWechatHandle('wechat')">
                    <img src="../../sprite/wechat_icon.png"
                         alt="">
                    <p>微信好友/群</p>
                </div>
                <div @click="shareWechatHandle('moments')">
                    <img src="../../sprite/friends_icon.png"
                         alt="">
                    <p>朋友圈</p>
                </div>
            </div>
        </van-action-sheet>

        <!-- 邀请好友海报 -->
        <InviteFriendsPopup v-if="showInviteFriendsPopup"
                            :showPosterInvite="showInviteFriendsPopup"
                            :superiorUrlInvite="superiorUrlInvite"
                            pageType="home"
                            @closePopup="handleClose"></InviteFriendsPopup>
        <v-tabbar :isAuthorized="isAuthorized" @onClickMy="handleClickMy"></v-tabbar>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import { Toast } from 'vant'
import './index.less'
import {
    setToken,
    getToken,
    removeToken,
    urlParse,
    stringNum
} from '../../utils/tokenUtils'
import {
    GetPageModuleInfo,
    GetUserCommissionRankList,
    GetProductList,
    GetUserInfo,
    GetUserAccountInfo,
    CreateUserInfo,
    GetShortLink,
    GetUserCommissionRotation
} from '../../service/home'
import Rule from './rule.vue'
export default {
    components: { Rule },
    data() {
        return {
            initLoading: true,
            topBanner: [],
            waistBanner: [],
            rankList: [],
            isVisible: false,
            loading: false,
            finished: false,
            pageIndex: 1,
            pageSize: 100,
            productList1: [],
            productList2: [],
            token: localStorage.getItem('token'),
            userInfo: null,
            totalAmount: 0,
            isBind: false,
            urlData: '',
            show: false,
            opacity: 1,
            productImg: '', // 海报商品图片
            productPrice: '', // 海报商品价格
            isAuthorized: false, // 是否授权初始化用户信息
            isApp: false,
            productSubImageList: [], // 分享主图
            shareData: {
                title: '',
                desc: '',
                shareUrl: '',
                shareIcon: ''
            },
            shareLink: '', // 分享链接
            postersImg: '', // 整张海报图
            postersImgList: [], // 整张海报图list
            i: 0, // 整张海报的索引
            postersList: [],
            isOutIn: false,
            imgLoading: false,
            timer: null,
            timer2: null,
            textList: [],
            count: 0,
            textShow: 1,
            showSheet: false,

            superiorUrlInvite: '', // 存储 邀请好友链接
            showInviteFriendsPopup: false, // 邀请海报显隐

            isSlide: true,
            timerSlide: null,

            posterNickName: '',
            isLoading: false
        }
    },
    created() {
        this.urlData = urlParse(window.location.href)

        if (this.urlData.token) {
            setToken('token', this.urlData.token)
            this.getUserInfo()
            return
        }
        if (getToken('token')) {
            this.getUserInfo()
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer)
        clearTimeout(this.timer2)
    },
    mounted() {
        clearTimeout(this.timer)
        clearTimeout(this.timer2)
        this.getPageModuleInfo()
        this.getUserCommissionRankList()
        this.getProductList()
        this.getUserCommissionRotation()

        // window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        // clearTimeout(this.timerSlide)
        // window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        onRefresh() {
            this.getPageModuleInfo()
            this.getUserCommissionRankList()
            this.getProductList()
            this.getUserCommissionRotation()
            if (getToken('token')) {
                this.getUserAccountInfo()
            }
    },
        // 点击 我的 tab页签时，判断是否登录，登录后判断是否授权
        handleClickMy(v) {
            // console.log('v', v)
            if (!getToken('token')) {
                this.goLogin()
                // window.location.href =
                //   'https://m.mgtv.com/login/?from=http://test.mgtv.com'
            }
            if (getToken('token')) {
                if (!v) {
                    this.isVisible = true
                }
                if (v) {
                    this.isVisible = false
                }
            }
        },
        // 点击 升级攻略
        goGrade() {
            this.$router.push('/grade')
        },
        // 点击 完整排行
        goRankTop() {
            this.$router.push('/rankTop')
        },
        handleTipText() {
            this.timer = setTimeout(() => {
                this.count++
                if (this.textList.length <= this.count) {
                    this.count = 0
                }
                this.$nextTick(() => {
                    this.timer2 = setTimeout(() => {
                        this.$refs.tipUser.style.opacity = 0
                    }, 2500)
                })
                this.$refs.tipUser.style.opacity = 1
                this.handleTipText()
            }, 4000)
        },
        getUserCommissionRotation() {
            GetUserCommissionRotation().then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.$nextTick(() => {
                        data.forEach((item) => {
                            item.beforeTime = dayjs(dayjs(item.orderTime)).diff(
                                dayjs(new Date()),
                                'hour'
                            )
                            if (!item.beforeTime) {
                                if (
                                    dayjs(dayjs(item.orderTime)).diff(
                                        dayjs(new Date()),
                                        'minute'
                                    ) == 0
                                ) {
                                    item.beforeTime = '刚刚'
                                } else {
                                    item.beforeTime =
                                        Math.abs(
                                            dayjs(dayjs(item.orderTime)).diff(
                                                dayjs(new Date()),
                                                'minute'
                                            )
                                        ) + '分钟前'
                                }
                            } else {
                                item.beforeTime =
                                    Math.abs(item.beforeTime) + '小时前'
                            }
                        })
                        this.textList = data
                        if (this.textList.length > 0) {
                            this.handleTipText()
                        }
                    })
                }
            })
        },
        // 点击 规则说明
        handleViewRule() {
            if (!getToken('token')) {
                this.goLogin()
            }
            if (getToken('token')) {
                this.$refs.refRule.isPromoteRuleShow = true
            }
        },
        viewAgreement(e) {
            this.$router.push(e)
        },
        getPageModuleInfo() {
            GetPageModuleInfo({
                pageType: 1,
                moduleType: 1
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.topBanner = data.list
                }
            })
            GetPageModuleInfo({
                pageType: 1,
                moduleType: 2
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.waistBanner = data.list
                }
            })
        },
        getUserCommissionRankList() {
            GetUserCommissionRankList({
                DataYear: dayjs().year(),
                DateMonth: dayjs().month() + 1,
                userId: 0
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data.saleRanks)
                    data.saleRanks.forEach((item) => {
                        if (item.saleAmount > 10000) {
                            item.saleAmountShort = (
                                item.saleAmount / 10000
                            ).toFixed(2)
                        } else {
                            item.saleAmountShort = item.saleAmount
                        }
                    })
                    this.rankList = data.saleRanks
                }
            })
        },
        getProductList() {
            GetProductList({
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.productList1 = []
                    this.productList2 = []
                    data.list.forEach((item, index) => {
                        if (index % 2 == 0) {
                            this.productList1.push(item)
                        } else if (index % 2 == 1) {
                            this.productList2.push(item)
                        }
                    })
                    this.loading = true
                    this.finished = true
                    // console.log(this.productList1)
                    // console.log(this.productList2)
                }
                this.initLoading = false
                this.isLoading = false
            })
        },
        getUserInfo() {
            GetUserInfo().then((res) => {
                // res = {code:'401',data:'',message:'未授权'}
                const { code, data, message } = res
                if (code == '0') {
                    console.log(data)
                    this.userInfo = data
                    this.posterNickName = stringNum(data.nickName, 14)
                    localStorage.setItem('nickName', data.nickName)
                    localStorage.setItem('avatarUrl', data.avatarUrl)
                    localStorage.setItem('channelCode', data.channelCode)
                    localStorage.setItem('mgUuid', data.mgUuid)
                    localStorage.setItem('id', data.id)
                    localStorage.setItem('shareId', data.shareId)
                    localStorage.setItem('superiorId', data.superiorId)
                    localStorage.setItem('userLevel', data.userLevel)
                    localStorage.setItem('isAuth', true)
                    this.isAuthorized = true
                    // 保存邀请好友的分享链接（以备后用）
                    // this.superiorUrlInvite =
                    //     process.env.NODE_ENV == 'development'
                    //         ? `http://test.mgtv.com/tpl/mclub/#/shareExtension?nickName=${encodeURIComponent(
                    //               data.nickName
                    //           )}&superiorId=${data.id}`
                    //         : `https://lego.mgtv.com/tpl/mclub/#/shareExtension?nickName=${encodeURIComponent(
                    //               data.nickName
                    //           )}&superiorId=${data.id}`
                    this.getProductList()
                    this.getUserAccountInfo()
                } else if (code == '402') {
                    this.userInfo = data
                    this.isVisible = true
                    // this.createUserInfo()
                } else if (code == '-1') {
                    // this.userInfo = data
                    // localStorage.setItem('nickName', data.nickName)
                    // localStorage.setItem('avatarUrl', data.avatarUrl)
                    // localStorage.setItem('channelCode', data.channelCode)
                    // localStorage.setItem('mgUuid', data.mgUuid)
                    // localStorage.setItem('id', data.id)
                    // localStorage.setItem('shareId', data.shareId)
                    // localStorage.setItem('superiorId', data.superiorId)
                    // localStorage.setItem('userLevel', data.userLevel)
                    // this.getUserAccountInfo()
                    localStorage.clear()
                    Toast(message)
                } else if (code == '401' && message == '未授权') {
                    localStorage.clear()
                }
            })
        },
        getUserAccountInfo() {
            GetUserAccountInfo().then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.totalAmount = data.totalAmount
                }
            })
        },
        createUserInfo() {
            CreateUserInfo({
                token: localStorage.getItem('token'),
                channelCode: this.urlData.channelCode,
                superiorId: this.urlData.superiorId,
                shareId: this.urlData.shareId
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    this.userInfo = data
                    this.posterNickName = stringNum(data.nickName, 14)
                    localStorage.setItem('nickName', data.nickName)
                    localStorage.setItem('avatarUrl', data.avatarUrl)
                    localStorage.setItem('channelCode', data.channelCode)
                    localStorage.setItem('mgUuid', data.mgUuid)
                    localStorage.setItem('id', data.id)
                    localStorage.setItem('shareId', data.shareId)
                    localStorage.setItem('superiorId', data.superiorId)
                    localStorage.setItem('userLevel', data.userLevel)
                    localStorage.setItem('userLevel', data.userLevel)
                    localStorage.setItem('isAuth', true)
                    this.isVisible = false
                    this.getProductList()
                }
            })
        },
        checkMore() {
            if (!getToken('token')) {
                this.goLogin()
                return
            }
            this.$router.push('/my')
        },
        goUrl(item) {
            if (!getToken('token')) {
                this.goLogin()
            }
            if (getToken('token')) {
                if (item.address) {
                    window.location.href = item.address
                }
            }
        },
        goRouter(url) {
            window.location.href = url
        },
        goLogin() {
            const superiorId = this.urlData.superiorId ? this.urlData.superiorId : ''
            window.location.href = `https://t.youku.com/yep/page/m/jca2kbelhq?wh_weex=true&isNeedBaseImage=1&redirect_url=${
                process.env.NODE_ENV == 'development' ? encodeURIComponent(window.location.href + '?superiorId=' + superiorId) : encodeURIComponent(window.location.href + '?superiorId=' + superiorId)
            }`
        },
        onLoad() {
            // this.getProductList();
        },

        // 获取商品分享海报链接
        getProductShareUrl(item) {
            GetShortLink({
                longLink: item.productUrl
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    console.log(data)
                    this.shareData.shareUrl = data
                    this.shareLink = data // 分享链接
                    this.creatQrCode(data)
                }
            })
        },

        parseValue(value) {
            return parseInt(value, 10)
        },
        DPR() {
            if (window.devicePixelRatio && window.devicePixelRatio > 1) {
                return window.devicePixelRatio
            }
            return 1
        },
        // 生成海报
        shareHandle(item) {
            console.log(item)
            if (!getToken('token')) {
                this.goLogin()
                return
            }
            if (!getToken('isAuth')) {
                this.isVisible = true
                return
            }

            this.imgLoading = true
            this.productSubImageList = []
            this.postersList = []
            this.postersImgList = []
            this.productSubImageList =
                JSON.parse(JSON.stringify(item.productSubImageList)) || []
            this.productSubImageList.unshift(item.productImage)
            if (this.productSubImageList.length > 0) {
                this.productSubImageList.forEach((item, idx) => {
                    this.postersList.push('_' + idx)
                })
            }
            this.productImg = item.productImage
            this.productPrice = item.salePrice

            this.shareData.title = item.productName
            this.shareData.shareIcon = item.productImage

            this.show = true
            this.i = 0
            this.opacity = 1
            this.$nextTick(() => {
                setTimeout(async () => {
                    await this.getProductShareUrl(item)
                    this.imgLoading = false
                }, 1500)
            })
        },
        drawCanvas() {
            const _this = this
            // window.pageYoffset = 0;
            // document.documentElement.scrollTop = 0;
            // document.body.scrollTop = 0;
            const { qrCodeUrl, refRule, tipUser, ...others } = this.$refs
            // console.log('others', others)
            const obj = {}
            for (const k in others) {
                if (k.indexOf('canvasRef_') != -1) {
                    obj[k] = others[k]
                }
            }
            const arr1 = Object.values(obj)
            // console.log('arr1', arr1)
            this.postersImgList = arr1.length > 0 && new Array(arr1.length)
            arr1.length > 0 &&
                arr1.forEach((item, idx) => {
                    const dom = item[0]
                    const box = window.getComputedStyle(dom)
                    const width = this.parseValue(box.width)
                    const height = this.parseValue(box.height)

                    const scaleBy = this.DPR()
                    const canvas = document.createElement('canvas')
                    // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
                    canvas.id = `canvansId_${idx}`
                    canvas.width = width * scaleBy
                    canvas.height = height * scaleBy
                    // 设定 canvas css宽高为 DOM 节点宽高
                    canvas.style.width = `${width}px`
                    canvas.style.height = `${height}px`
                    canvas.style.borderRadius = '8px'

                    const x = width
                    const y = height
                    html2canvas(item[0], {
                        canvas,
                        useCORS: true,
                        logging: true,
                        backgroundColor: 'transparent',
                        borderRadius: '20px'
                    }).then((canvas) => {
                        // console.log(canvas, 'canvas')
                        _this.convertCanvasToImage(canvas, x, y, idx)
                    })
                })
        },
        // 海报图片
        convertCanvasToImage(canvas, x, y, idx) {
            const image = new Image()
            image.width = x
            image.height = y
            image.className = `copyImg_${idx}`
            // image.src = `${idx}` // 自测用
            image.src = canvas.toDataURL('image/png')
            console.log('image', image)
            this.shareData.shareUrl = canvas.toDataURL('image/png')
            document
                .getElementsByClassName(`postersClass_${idx}`)[0]
                .appendChild(image)
            this.opacity = 0
            // this.postersImgList.push(image.src)
            // this.postersImgList = ['1', '2', '3', '4']
            this.postersImgList.splice(idx, 1, image.src)
            // console.log('this.postersImgList', this.postersImgList)
            // return image;
        },
        creatQrCode(url) {
            const _this = this
            QRCode.toDataURL(
                url,
                {
                    margin: 0.1,
                    quality: 0.3
                },
                (err, url) => {
                    if (err) return console.log(err)
                    // console.log('this.$refs.qrCodeUrl', this.$refs.qrCodeUrl)
                    const QRS = this.$refs.qrCodeUrl
                    QRS &&
                        QRS.forEach((item, idx) => {
                            const img = document.createElement('img')
                            img.className = `qrcodeImg_${idx}`
                            img.src = url
                            document
                                .getElementsByClassName(`qrcode_${idx}`)[0]
                                .appendChild(img)
                        })
                    setTimeout(() => {
                        _this.drawCanvas()
                    }, 100)
                }
            )
        },
        closePoster() {
            this.show = false
            this.productSubImageList = []
            this.postersList = []
            this.postersImgList = []
        },
        onChange(i) {
            this.productImg = ''
            this.postersImg = ''
            // console.log('i', i)
            this.i = i
            // console.log('GGGG', this.productSubImageList)
            // console.log('HHHH', this.postersImgList)
            this.productImg = this.productSubImageList[i]
            this.postersImg = this.postersImgList[i]
        },
        handleCopyAndSave(v) {
            if (v == 'LINK') {
                const Url = this.shareLink
                const oInput = document.createElement('input')
                oInput.value = Url
                document.body.appendChild(oInput)
                oInput.select() // 选择对象
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(oInput)
                this.$toast('链接复制成功，快去分享链接给好友吧！')
            }
            if (v == 'IMG') {
                this.$toast('请长按图片选择保存到手机')
            }
        },
        shareWechatHandle(type) {
            this.showSheet = false
            console.log(this.shareData)
            console.log(this.postersImg, 'postersImg')
            this.productImg = ''
            this.postersImg = ''
        },

        handleScroll() {
            clearTimeout(this.timerSlide)
            this.isSlide = false
            this.timerSlide = setTimeout(() => {
                this.isSlide = true // 邀请好友一起赚钱图标的动画效果
            }, 1000)
        },

        handleClose() {
            this.showInviteFriendsPopup = false
        }
    }
}
</script>

<style lang="less" scoped>
</style>
